import _ from 'lodash';

const initialState = {
  businessDatasets: [
    /* EXAMPLE
    {
      businessId: "5e92beb3b34aa9000870a532",
      businessDataRegion: 'usa',
      datasetName: "connectLast30Days",
      data: [],
      componentsWatching: [
        {id: 'ppp', lastSeen: Date.now()}
      ],
      status: 'watching',
      statusTimeStamp: 1588062018378,
      lastUpdate: 1588062018378,
      updateInterval: 30,
    }
    */
  ],
  userDatasets: [],

  availableBusinessReports: [
    {
      datasetName: "connectLast30Days",
    }
  ],
  availableUserReports: [],

};

const resetState = {
  businessDatasets: [],
  userDatasets: [],


  availableBusinessReports: [
    {
      datasetName: "connectLast30Days",
    }
  ],
  availableUserReports: [],
};


/*********************************************
 * REPORTING CONCEPT
 * - Datasets are maintained by REDUX and a GLOBAL TIMER
 * - Components register that they are "watching" the data, and therefore the 
 *    timers manage the data being updated.
 * - Components unmount and remove the watching status. 
 * - When no components are watching, the data is not updated. 
 * 
 * - When a component mounts, it requests a dataset. ACTION will check if the dataset
 *   exists already, identify if it needs updates. If the dataset doesn't exist, it will
 *   request the data from the backend.
 * 
 * 
 *  businessDatasets: [
 *    {
 *      businessId: "11111",
 *      datasetName: "connectLast30Days",
 *      data: [<all the data>],
 *      componentsWatching: [<array of components watching],
 *      status: updating/watching/idle,
 *      lastUpdate: <time when last updated>,
 *      updateIntervals: 30,
 *      timerObject: <timer>
 *    }
 *  ]
 * 
 * 
 *********************************************/


const ReportingReducer = (state = initialState, action) => {
  switch (action.type) {

    case 'CLEAR_STATE_REPORTING':
    {
      return resetState;
    }

    case 'RESET_STATE_REPORTING':
    {
      const clone = Object.assign({}, state);

      let mergedStateFromInitial = _.merge(initialState, clone);  

      return mergedStateFromInitial;
    }

    case 'REPORTING_COMPONENT_PING':
    {
      const clone = Object.assign({}, state);
      console.log("REDUCER: REPORTING_COMPONENT_PING");

      //Update the component watching date/time (As we expire stale reporting components)

      const indexOfReportingDataset = clone.businessDatasets.findIndex(rep => rep.businessId === action.data.businessId &&
      rep.datasetName === action.data.reportName);
      console.log(`Index of Report: ${indexOfReportingDataset}`);

      if (indexOfReportingDataset === -1) {
        return clone;
      }

      //Find the component index

      const indexOfRegisteredComponent = clone.businessDatasets[indexOfReportingDataset].componentsWatching.findIndex(component => component.id === action.data.componentId );
      console.log(`Index of Registered Component: ${indexOfRegisteredComponent}`);

      if (indexOfRegisteredComponent === -1) {
        //Not found
      } else {
        //Found
        clone.businessDatasets[indexOfReportingDataset].componentsWatching[indexOfRegisteredComponent].lastSeen = Date.now();
      }

      return clone;
    }

    case 'AWAITING_REPORTING_DATASET_UPDATE':
    {
      const clone = Object.assign({}, state);
      console.log("REDUCER: AWAITING_REPORTING_DATASET_UPDATE");
      
      //Traverse through and find businessId, Dataset and update
      const indexOfReportingDataset = clone.businessDatasets.findIndex(rep => rep.businessId === action.data.businessId &&
      rep.datasetName === action.data.reportName);
      console.log(`Index of Report: ${indexOfReportingDataset}`);

      if (indexOfReportingDataset === -1) {
        return clone;
      }
 
      clone.businessDatasets[indexOfReportingDataset].status = 'updating';
      clone.businessDatasets[indexOfReportingDataset].statusTimeStamp = Date.now();


      //Check all components/ remove inactive components.
      let currentTime = Date.now();
      let componentTimeToLive = 65;  //65 seconds
      console.log("Identify components to remove");
      for (let x = clone.businessDatasets[indexOfReportingDataset].componentsWatching.length -1; x >= 0; x--) {
        console.log(JSON.stringify(clone.businessDatasets[indexOfReportingDataset].componentsWatching[x]));

        let componentLastSeen = clone.businessDatasets[indexOfReportingDataset].componentsWatching[x].lastSeen;
        let componentLastSeenObj = new Date(componentLastSeen);
        let componentExpiresAtObj = new Date(componentLastSeenObj.getTime() + componentTimeToLive*1000);


        console.log(`TO REMOVE EXPIRED?: ${currentTime} > ${componentExpiresAtObj.valueOf()}`);
        if (currentTime > componentExpiresAtObj.valueOf()) {
          console.log(`Removing component as expired: ${clone.businessDatasets[indexOfReportingDataset].componentsWatching[x].id}`);
          clone.businessDatasets[indexOfReportingDataset].componentsWatching.splice(x, 1);
        }
      }


      

      return clone;
    }

    case 'RESET_REPORTING_DATASET_UPDATE':
    {
      const clone = Object.assign({}, state);
      console.log("REDUCER: RESET_REPORTING_DATASET_UPDATE");
      
      //Traverse through and find businessId, Dataset and update
      const indexOfReportingDataset = clone.businessDatasets.findIndex(rep => rep.businessId === action.data.businessId &&
      rep.datasetName === action.data.reportName);
      console.log(`Index of Report: ${indexOfReportingDataset}`);

      if (indexOfReportingDataset === -1) {
        return clone;
      }
      
      if (clone.businessDatasets[indexOfReportingDataset].componentsWatching.length > 0) {
        clone.businessDatasets[indexOfReportingDataset].status = 'watching';
        clone.businessDatasets[indexOfReportingDataset].statusTimeStamp = Date.now();
      } else {
        clone.businessDatasets[indexOfReportingDataset].status = 'idle';
        clone.businessDatasets[indexOfReportingDataset].statusTimeStamp = Date.now();
      }

      return clone;
    }

    case 'UPDATE_REPORTING_DATASET':
    {
      const clone = Object.assign({}, state);
      
      console.log("REDUCER: UPDATE_REPORTING_DATASET");

      //console.log(JSON.stringify(action));
      
      //Traverse through and find businessId, Dataset and update
      const indexOfReportingDataset = clone.businessDatasets.findIndex(rep => rep.businessId === action.data.businessId &&
      rep.datasetName === action.data.reportName);
      console.log(`Index of Report: ${indexOfReportingDataset}`);

      if (indexOfReportingDataset === -1) {
        return clone;
      }

      if (action.data.reportData !== null && action.data.reportData !== '' && action.data.reportData !== undefined) {
        clone.businessDatasets[indexOfReportingDataset].data = [action.data.reportData];    //Needs to be an array
        clone.businessDatasets[indexOfReportingDataset].lastUpdate = Date.now();
        clone.businessDatasets[indexOfReportingDataset].status = 'watching';
      }
      
      
      console.log("clone | UPDATE_REPORTING_DATASET");
      console.log(clone);

      return clone;
    }

    case 'REGISTER_REPORTING_COMPONENT':
    {
      const clone = Object.assign({}, state);
      

      /*******************************************************
       * Attempt to register this reporting component to an 
       * active report against this business.
       * If the report doesn't exist for this business, add it,
       * and then register the component to start watching.
       * Available reports are located in: availableBusinessReports.
       ******************************************************/
      console.log("REGISTER_REPORTING_COMPONENT");
      console.log(JSON.stringify(action));

      let insertedNewReport = false;

      //Check if the report is available for this business.
      let indexOfBusinessReportingDataset = clone.businessDatasets.findIndex(rep => rep.businessId === action.data.businessId &&
        rep.datasetName === action.data.reportName);
      console.log(`Index of Report Dataset: ${indexOfBusinessReportingDataset}`);
      if (indexOfBusinessReportingDataset === -1) {
        //We did not find it, search for this report in the available business reports and add it.
        try {
          let indexOfAvailableReport = clone.availableBusinessReports.findIndex(rep => rep.datasetName === action.data.reportName);
          if (indexOfAvailableReport === -1) {
            console.error(`Requested report does not exist: ${action.data.reportName}`);
          } else {
            //Add the report.
            clone.businessDatasets.push({
              businessId: action.data.businessId,
              businessDataRegion: 'aus',                  //TODO: Set to the region where the data is located. 
              datasetName: action.data.reportName,
              data: [],
              componentsWatching: [
                {
                  id: action.data.componentId,
                  lastSeen: Date.now(),
                }
              ],
              status: 'watching',
              statusTimeStamp: null,
              lastUpdate: null,
              updateInterval: 30,
            });
            insertedNewReport = true;

          }
        } catch (e) {}
      } else {
        //Update the existing component

        try {
          const indexOfRegisteredComponent = clone.businessDatasets[indexOfBusinessReportingDataset].componentsWatching.findIndex(component => component.id === action.data.componentId );
          console.log(`Index of Registered Component: ${indexOfRegisteredComponent}`);
  
          if (indexOfRegisteredComponent === -1) {
            //Insert
            console.log("Insert this component");
            clone.businessDatasets[indexOfBusinessReportingDataset].componentsWatching.push({
              id: action.data.componentId,
              lastSeen: Date.now(),
            })
  
          } else {
            //Looks like another component exists with this id
            console.log("This component id is already in use, if it has timed out, replace it.");
  
          }
        } catch (e) {
          console.log(e);
        }

      }


      
      /*
      //Traverse through and find businessId, Dataset and update
      let indexOfReportingDataset = clone.businessDatasets.findIndex(rep => rep.businessId === action.data.businessId &&
      rep.datasetName === action.data.reportName);
      console.log(`Index of Report Dataset: ${indexOfReportingDataset}`);

      if (indexOfReportingDataset === -1) {
        //Check if the report exists in local storage.
        const indexOfReport = clone.businessDatasets.findIndex(rep => rep.datasetName === action.data.reportName);
        console.log(`Index of Report: ${indexOfReport}`);
        if (indexOfReport === -1) {
          //Add the report
          
          clone.businessDatasets.push({
            businessId: action.data.businessId,
            businessDataRegion: 'usa',
            datasetName: action.data.reportName,
            data: [],
            componentsWatching: [],
            status: 'watching',
            statusTimeStamp: null,
            lastUpdate: null,
            updateInterval: 30,
          });

          console.log("businessDatasets");
          console.log(JSON.stringify(clone.businessDatasets));


          //Search again.
          let indexOfReportingDataset = clone.businessDatasets.findIndex(rep => rep.businessId === action.data.businessId &&
          rep.datasetName === action.data.reportName);
          console.log(`Second attempt: Index of Report Dataset: ${indexOfReportingDataset}`);

        }
      }

      try {
        const indexOfRegisteredComponent = clone.businessDatasets[indexOfReportingDataset].componentsWatching.findIndex(component => component.id === action.data.componentId );
        console.log(`Index of Registered Component: ${indexOfRegisteredComponent}`);

        if (indexOfRegisteredComponent === -1) {
          //Insert
          console.log("Insert this component");
          clone.businessDatasets[indexOfReportingDataset].componentsWatching.push({
            id: action.data.componentId,
            lastSeen: Date.now(),
          })

        } else {
          //Looks like another component exists with this id
          console.log("This component id is already in use, if it has timed out, replace it.");

        }
      } catch (e) {
        console.log(e);
      }
      */
      
      return clone;
    }
    
    case 'UNREGISTER_REPORTING_COMPONENT':
    {
      const clone = Object.assign({}, state);
      
      console.log("UNREGISTER_REPORTING_COMPONENT");
      console.log(JSON.stringify(action));
      
      //Traverse through and find businessId, Dataset and update
      const indexOfReportingDataset = clone.businessDatasets.findIndex(rep => rep.businessId === action.data.businessId &&
      rep.datasetName === action.data.reportName);
      console.log(`Index of Report: ${indexOfReportingDataset}`);

      if (indexOfReportingDataset === -1) {
        return clone;
      }



      const indexOfRegisteredComponent = clone.businessDatasets[indexOfReportingDataset].componentsWatching.findIndex(component => component.id === action.data.componentId );
      console.log(`Index of Registered Component: ${indexOfRegisteredComponent}`);

      if (indexOfRegisteredComponent === -1) {
        //Insert
        console.log("Component not found for removal");
        

      } else {
        //Looks like another component exists with this id
        console.log("Component found, start removal.");

        clone.businessDatasets[indexOfReportingDataset].componentsWatching.splice(indexOfRegisteredComponent, 1);

      }
      

      return clone;
    }

    default:
      return state;
  }
};

export default ReportingReducer;
